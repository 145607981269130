import './App.css';
import ApRouter from './ApRouter';

function App() {
  return (
    <div className="App">
      <ApRouter/>
    </div>
  );
}

export default App;
