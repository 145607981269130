import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
  import Home from "./pages/Home";
  import PaginaDeVenda from "./pages/protocolocabelorapunzel/PaginaDeVenda";
  
  function ApRouter() {
    return (
      <div>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/protocolocabelorapunzel" element={<PaginaDeVenda />} />
          </Routes>
          {/* <Footer />  */}
        </Router>
      </div>
    );
  }
  
  export default ApRouter;