import React, { useEffect, useState } from "react";

const Visualizacao = () => {
  //Visualizacao
  const [totalTimeInseconds, setTotalTimeInseconds] = useState(9999);
  const minutos = Math.ceil(totalTimeInseconds / 60);

  useEffect(() => {
    setTimeout(() => {
      setTotalTimeInseconds(totalTimeInseconds + 1);
    }, 50);
    //
  }, [totalTimeInseconds]);

  //
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {/*  */}
            <h6 id="h6Texto"  className="text-center">
              {minutos} Pessoas estão assistindo esse vídeo agora
            </h6>
            {/*  */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Visualizacao;
