import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div id="bgFundo">
          <div className="container" >
             <div className="row">
              {/*  */}
              <div className="parabens">
              <div className="parabens-card">
                 <h1>Parabéns</h1>
                 <span>O vídeo foi liberado para você!</span>
                 <div id="btnLink" className='elemento-piscando'>
                    <Link to="/protocolocabelorapunzel" className='animated-button'>
                    <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                        Clique para assistir agora!
                    </Link>
                 </div>
              </div>
              </div>
              {/*  */}
             </div>
           </div>
    </div>
  )
}

export default Home
