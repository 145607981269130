import React, { useEffect, useRef, useState } from "react";
import video from "../../video/R$97.mp4";
import { FaVolumeMute } from "react-icons/fa";
// import Minuto from '../../pages/Minuto';
import { IconContext } from "react-icons";
import { BiPlay, BiPause } from "react-icons/bi";
import "animate.css";

const Videos = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState([0, 0]);
  const [currentTimeSec, setCurrentTimeSec] = useState();
  const [duration, setDuration] = useState([0, 0]);
  const [durationSec, setDurationSec] = useState();
  //
  const [inicio, setInicio] = useState(false);
  const [tempoBotao, setTempoBotao] = useState(false);
  const [repetir, setPepetir] = useState(false);
  const [progress, setProgress] = useState(0);

  //Hover
  // const [isHovering, setIsHovering] = useState(false);

  // const handleMouseOver = () => {
  //   setIsHovering(true);
  // };

  // const handleMouseOut = () => {
  //   setIsHovering(false);
  // };

  // Minutos de video
  const sec2Min = (sec) => {
    const min = Math.floor(sec / 60);
    // const secRemain = Math.floor(sec % 100);
    const secRemain = Math.floor(sec % 60);
    return {
      min: min,
      sec: secRemain,
    };
  };

  //

  useEffect(() => {
    const { min, sec } = sec2Min(videoRef.current.duration);
    setDurationSec(videoRef.current.duration);
    setDuration([min, sec]);
    //
    if (min === 1) {
    }
    //
    const interval = setInterval(() => {
      const { min, sec } = sec2Min(videoRef.current.currentTime);
      setCurrentTimeSec(videoRef.current.currentTime);
      setCurrentTime([min, sec]);
    }, 1000);

    return () => clearInterval(interval);
  }, [isPlaying]);

  // Barra de progress
  const handleProgress = () => {
    const duration = videoRef.current.duration;
    const currentTime = videoRef.current.currentTime;
    const progress = (currentTime / duration) * 100;
    setProgress(progress);
  };

  //
  const togglePlay2 = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
      // setInicio(true);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
      // setInicio(true);
    }
  };
  //

  const togglePlay = () => {
    if (isPlaying) {
      setPepetir(true);
    } else {
      videoRef.current.play();
      // setIsPlaying(true);
      setInicio(true);
      setPepetir(true);
    }
  };
  //
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h4
              className="text-center"
              id="esseTitulo"
              style={{ marginTop: "20px", color: "#fff", fontWeight: "bold" }}
            >
              Conheça o{" "}
              <span style={{ color: "#09e226" }}>Segredo Das Indianas</span>{" "}
              Para Ter Um Cabelo <br /> Liso Sem Produto Caros E Sem Salão de
              Beleza!
            </h4>
          </div>
          {/*  */}
          {inicio ? (
            <div>
              {repetir ? (
                <div
                  id="target"
                  // onMouseOver={handleMouseOver}
                  // onMouseOut={handleMouseOut}
                >
                  <video
                    id="video"
                    onTimeUpdate={handleProgress}
                    ref={videoRef}
                    autoPlay
                    playsinline="true"
                  >
                    <source src={video} type="video/mp4" />
                  </video>
                  {/*  */}
                  {currentTime[0] === 19 || currentTime[0] === 20 || currentTime[0] === 21 ? (
                    <div className="texte">
                      <a
                        href="
https://pay.hotmart.com/Q81143119K"
                        className="a-btn"
                      >
                        <span className="span-btn">COMPRAR AGORA</span>
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*  */}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div id="videoMrg">
              <video
                onTimeUpdate={handleProgress}
                ref={videoRef}
                id="video"
                // controls
                autoPlay
                muted
                playsinline="true"
              >
                <source src={video} type="video/mp4" />
              </video>
              {/*  */}
              <div className="ouvirVideo">
                <button onClick={togglePlay} id="btnInicio">
                  <span>Seu vídeo já começou</span>
                  <br />
                  <FaVolumeMute />
                  <br />
                  <span>Clique para ouvir</span>
                </button>
              </div>
              {/*  */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
