// import logo from "../../img/ultrasecreto.svg";


const Header = () => {

  const data = new Date();

  const dia = String(data.getDate()).padStart(2, '0');

  const mes = String(data.getMonth() + 1).padStart(2, '0');

  const ano = data.getFullYear();

  const dataAtual = dia + '/' + mes + '/' + ano;

// console.log(dataAtual);

    return (
        <div>
        <header>
           <div className="container">
             <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3 className="tituloPV"><b>Dia {dataAtual} é o último dia para ver esse vídeo</b></h3>
              </div>
             </div>
           </div>
         </header>
        </div>
    );
}

export default Header;