import React, { useEffect, useState } from "react";
import user1 from "../../img/comentarios/m2.jpeg";
import user3 from "../../img/comentarios/m4.jpeg";
import user5 from "../../img/comentarios/m5.jpeg";
import user6 from "../../img/comentarios/m6.jpeg";
import user12 from "../../img/comentarios/m12.jpeg";
import user14 from "../../img/comentarios/m14.jpeg";
import user15 from "../../img/comentarios/m15.jpeg";
import user17 from "../../img/comentarios/m17.jpeg";


import $ from "jquery";

const Comentario = () => {
// 
  const loadMore = () => {
    $("#more").show();
    $(".fb-comments-loadmore").hide().remove();
  };

  useEffect(() => {
    loadMore();
  }, []);

  $("like").on("click", function () {
    if ($(this).hasClass("liked")) {
      $(this)
        .parents(".fb-comments-wrapper, .fb-comments-reply-wrapper")
        .find("likes")
        .text(
          parseInt(
            $(this)
              .parents(".fb-comments-wrapper, .fb-comments-reply-wrapper")
              .find("likes")
              .text()
          ) - 1
        );
      $(this).removeClass("liked");
      $(this).text("Curtir");
    } else {
      $(this)
        .parents(".fb-comments-wrapper, .fb-comments-reply-wrapper")
        .find("likes")
        .text(
          parseInt(
            $(this)
              .parents(".fb-comments-wrapper, .fb-comments-reply-wrapper")
              .find("likes")
              .text()
          ) + 1
        );
      $(this).addClass("liked");
      $(this).text("Descurtir");
    }
  });

  //
  return (
    <div>
      {/*  */}
      <section className="comments py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              {/* <div className="fb-comments" style="margin-top: 5px;"> */}
              <div className="fb-comments">
                <div className="fb-comments-header">
                  <span>Mostrando 8 comentarios</span>
                </div>
                <div className="fb-comments-wrapper" id="2">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user1} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Valesca Silva</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Feliz demai!!! Gentee, eu assiti o vídeo até o final,
                          mas achei que não daria comentarios certo para mim,
                          porém resolvi tentar. E em apenas um mês aplicando as
                          técnicas corretamente já obtive resultados no meu
                          cabelo, amanduuu!!! 😘
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              23
                            </span>
                          </likes>
                          <date>4 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="3">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user17} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Vitória Pereira</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Realmente, assistir até o final me fez abrir os olhos
                          e é 100% seguro.
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              90
                            </span>
                          </likes>
                          <date>5 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="4">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user3} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Mariana Martins</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Meninas, a dica do final do vídeo compensa demais!!!
                          Estou amando essas receitas!!
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              70
                            </span>
                          </likes>
                          <date>12 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="fb-comments-wrapper" id="5">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user5} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Juliana Castro</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Devia vender esse método por um milhããão eles
                          funcionam mesmo! Eu já tinha feito de tudo para alisar
                          meu cabelo em casa sem formol, mas sem o seu método
                          eles não evoluiam quase nada, agora eles estão
                          perfeitos do jeito que eu queriaa.
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                        <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              100
                            </span>
                          </likes>
                          <date>19 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="6">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user6} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Isabella Lopes</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Estou amando os resultados, supeeer recomendo esse
                          método é maravilhoso e deu um up nos meus
                          resultados.😘
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                        <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              80
                            </span>
                          </likes>
                          <date>24 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="7">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user12} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Roberta Gomes</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Realmente funciona, minha barriga tem diminuído a cada
                          dia. Obrigada por compartilhar 🥰
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                        <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              60
                            </span>
                          </likes>
                          <date>26 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="9">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img src={user14} />
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Rafaela Souza</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">
                          Eu mesmo tinha que usar essa receita para ver se
                          funciona, gente receita testada e aprovada
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                        <like>Curtir</like>·<reply>Responder</reply>·
                          <likes>
                            <span>
                              <i
                                className="fa-solid fa-heart"
                                style={{ color: "red", marginRight: "10px" }}
                              ></i>
                              <i
                                className="fa-solid fa-thumbs-up"
                                style={{ marginRight: "10px" }}
                              ></i>
                              200
                            </span>
                          </likes>
                          <date>39 min</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                {/* <button className="fb-comments-loadmore" onclick="javascript:loadMore();">Carregar mais 5 comentários</button> */}
                <button className="fb-comments-loadmore">
                  Carregar mais 1 comentários
                </button>
                <div style={{ display: "none" }} id="more">
                  <div className="fb-comments-wrapper">
                    <table className="fb-comments-comment" id="10">
                      <tbody>
                        <tr>
                          <td rowspan="3" className="fb-comments-comment-img">
                            <img src={user15} />
                          </td>
                          <td>
                            <font className="fb-comments-comment-name">
                              <name>Ana Sofia</name>
                            </font>
                          </td>
                        </tr>
                        <tr>
                          <td className="fb-comments-comment-text">
                            Meninas eu tenho 57 anos e tô de cara com os
                            resultados que estou tendo, minha auto estime
                            aumentou muito, me sinto belíssima!!
                          </td>
                        </tr>
                        <tr>
                          <td className="fb-comments-comment-actions">
                            <like>Curtir</like>·<reply>Responder</reply>·
                            <likes>
                              <span>
                                <i
                                  className="fa-solid fa-heart"
                                  style={{ color: "red", marginRight: "10px" }}
                                ></i>
                                <i
                                  className="fa-solid fa-thumbs-up"
                                  style={{ marginRight: "10px" }}
                                ></i>
                                41
                              </span>
                            </likes>
                            <date>1 h</date>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="fb-comments-wrapper" id="11">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img"><img
                            src="./assets/img/comentarios/m15.jpeg">
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Laura Flores</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">Eu já estou tomando, já eliminei 7 kls, já fez muita diferença, minhas roupas estavam todas apertadas,  ágora já estão largas, estou num pós operatório de coluna por isso engordei muito 15 Kg, estava sentindo muita dor agora amenizou muito depois que emagreci já consigo andar mais q antes 🙏🙏🙏🙏🙏🙏</td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>
                          ·
                          <reply>Responder</reply>
                          ·
                          <likes>40</likes>
                          
                          <date>1 h</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="12">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img"><img
                            src="./assets/img/comentarios/m16.jpeg">
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Daniela Costa</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">Muito Obrigada tem me ajudado e muito,Adorei ...Durmo super bem e vendo resultado ..🌹🥰🙋</td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>
                          ·
                          <reply>Responder</reply>
                          ·
                          <likes>47</likes>
                          
                          <date>1 h</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper" id="13">
                  <table className="fb-comments-comment">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img"><img
                            src="./assets/img/comentarios/m17.jpeg"></td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Juliana Dias</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">Em duas semanas comecei a ver resultados, muito bom</td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>
                          ·
                          <reply>Responder</reply>
                          ·
                          <likes>50</likes>
                          
                          <date>1 h</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="fb-comments-wrapper">
                  <table className="fb-comments-comment" id="14">
                    <tbody>
                      <tr>
                        <td rowspan="3" className="fb-comments-comment-img">
                          <img
                            src="./assets/img/comentarios/m18.jpeg">
                        </td>
                        <td>
                          <font className="fb-comments-comment-name">
                            <name>Isabelle Correia</name>
                          </font>
                        </td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-text">Meu irmão perdeu 6 kl em 15 dias... vou começar hj rsrs</td>
                      </tr>
                      <tr>
                        <td className="fb-comments-comment-actions">
                          <like>Curtir</like>
                          ·
                          <reply>Responder</reply>
                          ·
                          <likes>66</likes>
                          
                          <date>1 h</date>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Comentario;
