import React from 'react'
import Header from "../../components/layout/Header"
import Footer from "../../components/footer/Footer"
import Video from "../../components/video/Videos"
import Comentario from '../../components/comentario/Comentario';
import Visualizacao from '../../components/visualizacao/Visualizacao';

const PaginaDeVenda = () => {
  return (
    <div id='bgFundo'>
      <Header/>
      <Video />
      <Visualizacao/>
      <Comentario/>
      <Footer/> 
    </div>
  )
}

export default PaginaDeVenda
