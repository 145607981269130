
const Footer = () => {

    const data = new Date();

    const ano = data.getFullYear();
  
    const anoAtual = ano;
 
    return (
        <div>
            {/* <!-- Footer Start --> */}

            <footer className="py-3 text-center" style={{ backgroundColor: "#2f0258" }}>
             <div className="text-white">
                 <div>
                    Copyright {anoAtual} - Protocolo Cabelo Rapunzel
                 </div>
                 <div>
                    Todos os Direitos Reservados
                 </div>
                {/* <a href="termos.html" className="text-white">
             <br/>
                Termos de Uso</a> | <a href="privacidade.html" className="text-white">Privacidade</a> */}
             </div>
            </footer>


      
        </div>
    );
}

export default Footer;